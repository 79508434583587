@import colours

.status
  display: inline
  border: 1px solid $grey1
  border-radius: 0.4em
  font-size: 0.8em
  padding: 0 0.4em
  margin: 0 0.4em 0 0.4em

  width: 5em
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  text-align: center

  cursor: default

  &.preanalysis
    border: 1px solid $govukBlue
    color: $govukBlue

  &.analysis
    border: 1px solid $lightBlue
    color: $lightBlue

  &.development
    border: 1px solid $fuschia
    color: $fuschia

  &.testing
    border: 1px solid $orange
    color: $orange

  &.features
    border: 1px solid $yellow
    color: $grey1

  &.features
    border: 1px solid $yellow
    color: $grey1

  &.acceptance
    border: 1px solid $pink
    background-color: lighten($pink, 42%)
    color: $pink

  &.closed
    border: 1px solid $grassGreen
    background-color: lighten($grassGreen, 50%)
    color: $grassGreen
