@import _colours

.requirementList
  .requirement
    > .features
      margin: 1em -1em -1em -1em
      padding: 1em 1.5em 1em 1.5em
      background-color: lighten($grey1, 50%)

      > div
        margin: 1.5em 0
        font-size: 0.8em

        .progressBar
          margin: 0.4em 0 0 0
