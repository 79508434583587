@import colours

.people
  padding: 2em

  .plusEstimation
    color: $green

  .minusEstimation
    color: $red
