.graphPage
  padding: 2em

  .dashboardPaper
    margin: 3em 0 3em 0
    padding: 0 1em 1em 1em
    overflow-x: auto
    overflow-y: hidden

  .issueListItem
    margin: 0 0.3em

    img
      vertical-align: bottom
      margin: 0 0.2em
