@import colours

#epicList
  margin: 2em 0
  .MuiAccordionDetails-root
    display: block

  .MuiAccordionSummary-content
    > a
      flex-basis: 4em

    > span:first-of-type
      flex-basis: 30%

    > *:first-child
      margin-left: 0

    > *
      margin: 0 0.5em

    > div.pb
      flex-basis: 50%
      display: flex
      
      .progressBar
        margin: 0 1em

    .labels
      text-align: right
      flex-basis: 20%

      span
        background-color: $grey4
        color: $grey1
        font-size: 0.8em
        padding: 0.1em 0.3em
        margin: 0 0.3em 0 0
        border-radius: 0.3em

  .smallissues
    display: flex

    > span:first-of-type
      width: calc(30% + 4.5em)
      display: flex
      flex-wrap: wrap
      margin: 0 0.3em 0 -0.35em
      
      > span
        white-space: nowrap

        img 
          vertical-align: text-bottom
          margin: 0 0.25em 0 0.35em

  .requirement
    display: flex
    margin: 0.8em 0

    > span:first-of-type
      > img
        vertical-align: text-bottom
        margin: 0 0.5em 0 0

      > a
        width: 4em
        display: inline-block
        margin: 0 1em 0 0

      flex-basis: calc(30% - 1em)
      overflow: hidden
      text-overflow: ellipsis
      display: block
      white-space: nowrap
