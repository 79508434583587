@import _colours

img.jiraIcon
  vertical-align: text-bottom
  margin: 0 0.2em 0 0

.featureList, .requirementList
  .feature, .requirement
    width: 20em
    min-height: 30em
    padding: 1em 1em
    overflow: hidden
    overflow-y: auto

    h2
      font-size: 1em
      padding-bottom: 0.2em

      img
        vertical-align: text-bottom
        margin-right: 0.4em

    .sumProgress
      margin: 1em 0 2em 0

    .estimation
      display: flex
      margin: 1em 0

      > span:first-of-type
        flex-basis: 3em

    .roleEstimates
      .progressBar
        margin: 0 1em

    .requirementEpic
      img:first-child
        margin-left: 0

      img
        vertical-align: text-bottom
        margin: 0 0.2em 0 0.6em

      > span
        margin-left: 0.2em
        color: $grey1
        font-size: 0.6em
    .assignees
      font-size: 0.8em
      
    .bugs
      img
        width: 16px
        height: 16px
        vertical-align: text-bottom

      font-size: 0.8em
      a
        margin: 0 0.3em
        color: $errorColour

        &.notBug
          color: $grey2
        &.releasedBug
          color: $grey1
        &.resolvedBug
          color: $black

    .versions
      a
        color: $grey1
        margin: 0 0.3em

        &.released
          color: $green

    .labels
      span
        background-color: $grey4
        border: 1px solid $grey3
        color: $grey1
        font-size: 0.8em
        padding: 0.2em 0.4em
        margin: 0 0.3em 0 0
        border-radius: 0.3em

  .requirement
    min-height: 15em
