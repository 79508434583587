@import colours

.version
  padding: 1em 2em
  margin: 1em 0 2em 0

  .issueListItem
    margin: 0 0.3em
    display: inline-block

    img
      width: 16px
      height: 16px
      vertical-align: text-bottom
      margin: 0 0.2em

    &.notBug a
      color: $grey2
    &.resolvedBug a
      color: $black
    &.releasedBug a
      color: $grey1
